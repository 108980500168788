import { useRef } from "react";
import ScrollIntoView from "../../utils/ScrollIntoView";

import "./OffersSection.scss";
import CountDownCircle from "./CountDownCricle";
import { t } from "i18next";

const OffersSection = ({ scrollIntoView }) => {
  const secRef = useRef();

  ScrollIntoView({
    sectionName: "offers_section",
    scrollIntoView,
    secRef,
  });

  return (
    <section ref={secRef} className="offers-section">
      <div className="custom-container">
        <div className="section-body">
          <div className="sec-text">
            <h2
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="800"
              className="bold-font"
            >
              {t("harryUp")}
            </h2>
            <h3 data-aos="fade-up" data-aos-delay="500" data-aos-duration="800">
              {t("offerTime")}
            </h3>
            <h4 data-aos="fade-up" data-aos-delay="700" data-aos-duration="800">
              {t("getOffer")}
            </h4>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="700"
            data-aos-duration="800"
            className="sec-timer"
          >
            <CountDownCircle />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OffersSection;
