/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { MenuOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import lngs from "../../languages";
import sleep from "../../utils/sleep";
import LoadingModal from "../loading-modal/LoadingModal";
import Logo from "../logo/Logo";
import mainAppBarLinks from "./mainAppBarLinks";
import "./MainAppBarMd.scss";

const MainAppBarMd = ({ setScrollIntoView, className, exceeds0 }) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const [foundIndex, setFoundIndex] = useState("");
  useEffect(() => {
    setFoundIndex(lngs.findIndex((obj) => obj.code === i18n.language));
  }, [i18n.language]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   if (allFetchedFilterCats?.length > 0) {
  //     setItems(
  //       allFetchedFilterCats.map((filterCat) => {
  //         return getItem(
  //           { filterCat },
  //           filterCat?.id,
  //           null,
  //           filterCat?.cats?.length > 0
  //             ? filterCat.cats.map((cat) => {
  //                 return getItemWithLink(
  //                   {
  //                     filterCat,
  //                     cat
  //                   },
  //                   cat?.id,
  //                   null
  //                 );
  //               })
  //             : null
  //         );
  //       })
  //     );
  //   }
  // }, [allFetchedFilterCats]);

  const DrawerTitle = () => {
    return (
      <p
        style={{
          textAlign: "end",
        }}
      >
        {i18n.language === "ar" ? "فلاش ديل" : "The Flash Deal"}
      </p>
    );
  };

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(i18n, t).map(({ id, name }) => (
          <li key={id}>
            <button
              onClick={() => {
                setScrollIntoView(id);
                setVisible(false);
              }}
              // active-link main-nav-link
              className="main-nav-link"
            >
              <div className="main-nav-link-div">
                <span>{name}</span>
                <div className="active-img-wrap"></div>
              </div>
            </button>
          </li>
        ))}
        {/*  */}
      </ul>
    );
  };

  return (
    <>
      <div className={className}>
        <div className="custom-container">
          <div className="nav-content-wrapper">
            <div className="nav-lang-wrapper">
              <Button
                style={{
                  color: "#000",
                }}
                onClick={showDrawer}
                edge="start"
                // className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuOutlined />
              </Button>
              <button
                type="button"
                onClick={async () => {
                  setLoadingModalVisible(true);
                  const nextIndex =
                    foundIndex + 1 < lngs.length ? foundIndex + 1 : 0;
                  i18n.changeLanguage(lngs[nextIndex].code);
                  await sleep(600);
                  setLoadingModalVisible(false);
                }}
                className="lang-button"
              >
                <span
                  className={`fi fi-${
                    lngs[foundIndex + 1 < lngs.length ? foundIndex + 1 : 0]
                      .country_code
                  }`}
                ></span>
              </button>
            </div>

            <Logo colored className="main-app-bar-logo" />
          </div>
        </div>
        <Drawer
          title={<DrawerTitle />}
          placement={i18n.dir() === "ltr" ? "left" : "right"}
          onClose={onClose}
          open={visible}
          className="header-md-drawer"
          width={292}
          // anchor="right"
        >
          <div className="logo-links">
            {/* <Logo className="main-app-bar-logo" /> */}
            {renderNavLinks()}
          </div>
        </Drawer>
      </div>

      {loadingModalVisible && <LoadingModal />}
    </>
  );
};

export default MainAppBarMd;
