/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";

import MainAppBarLg from "./MainAppBarLg";
import MainAppBarMd from "./MainAppBarMd";

const MainAppBar = ({ setScrollIntoView }) => {
  // detect route

  /////////////////////////////////////////

  const [scrollY, setScrollY] = useState(
    window.scrollY || document.documentElement.scrollTop
  );
  const [scrollDir, setScrollDir] = useState(null);
  useEffect(() => {
    const handleScroll = () => {
      if ((window.scrollY || document.documentElement.scrollTop) > scrollY) {
        setScrollDir("scroll-down");
      } else {
        setScrollDir("scroll-up");
      }
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);

  return (
    <>
      <MainAppBarLg
        setScrollIntoView={setScrollIntoView}
        exceeds0={scrollY > 0}
        className={`main-app-bar main-app-bar-lg extra-bar light ${
          scrollDir ? scrollDir : ""
        } ${scrollY > 0 ? "exceeds0" : ""}`}
      />
      <MainAppBarMd
        setScrollIntoView={setScrollIntoView}
        exceeds0={scrollY > 0}
        className={`main-app-bar main-app-bar-md extra-bar light ${
          scrollDir ? scrollDir : ""
        } ${scrollY > 0 ? "exceeds0" : ""}`}
      />
    </>
  );
};

export default MainAppBar;
