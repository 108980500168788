/* eslint-disable react-hooks/exhaustive-deps */
import { ConfigProvider } from "antd";
import Aos from "aos";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getGeneralSettingsApi from "../../apis/general/generalSettingsApi.js";
import axiosDefaults from "../../axiosDefaults.js";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest.jsx";
import HomePage from "../../home-page/HomePage.jsx";
import "../../i18n";
import checkRes from "../../utils/checkRes.js";
import MainAppBar from "../main-app-bar/MainAppBar.jsx";
import MainAppFooter from "../main-app-footer/MainAppFooter.jsx";
import { ToastContainer } from "react-toastify";

const App = () => {
  const { i18n } = useTranslation();
  const [scrollIntoView, setScrollIntoView] = useState(null);
  useEffect(() => {
    const func = () => {
      setScrollIntoView("");
    };
    window.addEventListener("scroll", func);

    return () => {
      window.removeEventListener("scroll", func);
    };
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  axiosDefaults(
    null,
    () => {},
    () => {},
    i18n.language
  );

  const [isLoading, setIsLoading] = useState(false);
  const [fetchedSettings, setFetchedSettings] = useState(null);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getGeneralSettingsApi(),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedSettings(res.data.data);
          } else {
            setFetchedSettings([]);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => (isMounted = false);
  }, [i18n.language]);

  return (
    <ConfigProvider
      direction={i18n.dir()}
      theme={{
        token: {
          colorPrimary: "#8c56a6",
          fontFamily: "'MavenPro-Regular' !important",
        },
      }}
    >
      <div dir={i18n.dir()} className={`app app-${i18n.dir()}`}>
        <MainAppBar setScrollIntoView={setScrollIntoView} />
        <HomePage
          isLoading={isLoading}
          fetchedSettings={fetchedSettings}
          scrollIntoView={scrollIntoView}
        />
        <MainAppFooter fetchedSettings={fetchedSettings} />
      </div>
      <ToastContainer closeButton={false} />
    </ConfigProvider>
  );
};

export default App;
