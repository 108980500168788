import { t } from "i18next";
import { useRef } from "react";
import ScrollIntoView from "../../utils/ScrollIntoView";
import parse from "html-react-parser";
import "./TermsSection.scss";

const TermsSection = ({ scrollIntoView, fetchedSettings }) => {
  const secRef = useRef();

  ScrollIntoView({
    sectionName: "terms_section",
    scrollIntoView,
    secRef,
  });

  if (fetchedSettings?.terms)
    return (
      <section ref={secRef} className="terms-section">
        <div className="custom-container">
          <div className="section-body">
            <div className="sec-text">
              <h3
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="800"
              >
                {t("termsConditions")}
              </h3>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
              className="terms-data"
            >
              {parse(fetchedSettings?.terms)}
            </div>
          </div>
        </div>
      </section>
    );
  return null;
};

export default TermsSection;
