/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import lngs from "../../languages";
import Logo from "../logo/Logo";
import "./MainAppBarLg.scss";
import "flag-icons/css/flag-icons.min.css";
import mainAppBarLinks from "./mainAppBarLinks";
import sleep from "../../utils/sleep";
import LoadingModal from "../loading-modal/LoadingModal";

const MainAppBarLg = ({ setScrollIntoView, className, exceeds0 }) => {
  const { t, i18n } = useTranslation();
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const [foundIndex, setFoundIndex] = useState("");
  useEffect(() => {
    setFoundIndex(lngs.findIndex((obj) => obj.code === i18n.language));
  }, [i18n.language]);
  return (
    <div className={`${className} user-nav`}>
      <div className="custom-container">
        <div className="logo-links-lng-btn-wrap">
          <Logo colored={exceeds0} />
          <div className="links-wrap">
            <ul className="links-ul">
              {mainAppBarLinks(i18n, t).map((item) => (
                <button
                  key={item.id}
                  onClick={() => {
                    setScrollIntoView(item.id);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </ul>
          </div>
          <button
            type="button"
            onClick={async () => {
              setLoadingModalVisible(true);
              const nextIndex =
                foundIndex + 1 < lngs.length ? foundIndex + 1 : 0;
              i18n.changeLanguage(lngs[nextIndex].code);
              await sleep(600);
              setLoadingModalVisible(false);
            }}
            className="lang-button"
          >
            <span
              className={`fi fi-${
                lngs[foundIndex + 1 < lngs.length ? foundIndex + 1 : 0]
                  .country_code
              }`}
            ></span>
          </button>
        </div>
      </div>

      {loadingModalVisible && <LoadingModal />}
    </div>
  );
};

export default MainAppBarLg;
