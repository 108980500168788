/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ScrollIntoView from "../../utils/ScrollIntoView";
import { Button, Form } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "./ContactSection.scss";
import { useTranslation } from "react-i18next";
import AntdTextField from "../../common/antd-form-components/AntdTextField";
import AntdTextarea from "../../common/antd-form-components/AntdTextarea";
import LoadingModal from "../../components/loading-modal/LoadingModal";
import mapImg from "../../assets/imgs/contact.jpg";
import useCustomApiRequest from "../../custom-hooks/useCustomApiRequest";
import contactApi from "../../apis/general/contactApi";
import checkRes from "../../utils/checkRes";

const ContactSection = ({ scrollIntoView }) => {
  const secRef = useRef();
  ScrollIntoView({
    sectionName: "contact_section",
    scrollIntoView,
    secRef,
  });

  const { t, i18n } = useTranslation();
  const [isLoading] = useState(false);
  const generalLabelStr = (v) => t(`contactForm.${v}.label`);
  const generalrequiredErrStr = (v) => t(`contactForm.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`contactForm.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`contactForm.${v}.errors.min`, {
      min,
    });

  const [form] = Form.useForm();
  const {
    control,
    handleSubmit,
    reset,
    // setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(generalrequiredErrStr("name")),
        phone: Yup.string()
          .required(generalrequiredErrStr("phone"))
          .matches(/^[0-9]+$/, generalTypeErrorStr("phone"))
          .min(10, generalMinErrorStr("phone", 10)),
        email: Yup.string()
          .required(generalrequiredErrStr("email"))
          .email(generalTypeErrorStr("email")),
        message: Yup.string().required(generalrequiredErrStr("message")),
      })
    ),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
  });

  useEffect(() => {
    if (errors && Object.keys(errors)?.length > 0) {
      reset({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    }
  }, [i18n.language]);

  const customApiRequest = useCustomApiRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("message", data.message);

    setIsSubmitting(true);
    customApiRequest(
      contactApi(),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          reset({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  return (
    <section ref={secRef} id="contact_section" className="contact-section">
      <div className="custom-container">
        <div className="section-content-wrap">
          <div className="contact-form">
            <p className="form-t">{t("contactForm.formTitle")}</p>
            <Form
              className="custom-shared-form saloon-settings-form"
              form={form}
              layout="vertical"
              onFinish={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="name"
                    type="text"
                    errorMsg={errors?.name?.message}
                    validateStatus={errors?.name ? "error" : ""}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr("name")}</p>
                </div>
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="phone"
                    type="text"
                    errorMsg={errors?.phone?.message}
                    validateStatus={errors?.phone ? "error" : ""}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr("phone")}</p>
                </div>
                <div className={`custom-text-field-wrapper ${i18n.dir()}`}>
                  <AntdTextField
                    className="custom-text-field"
                    name="email"
                    type="text"
                    errorMsg={errors?.email?.message}
                    validateStatus={errors?.email ? "error" : ""}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr("email")}</p>
                </div>

                <div className={`custom-text-area-wrapper ${i18n.dir()}`}>
                  <AntdTextarea
                    rows={6}
                    className="custom-text-area"
                    name="message"
                    errorMsg={errors?.message?.message}
                    validateStatus={errors?.message ? "error" : ""}
                    control={control}
                  />
                  <p className="custom-label">{generalLabelStr("message")}</p>
                </div>

                <div className="save-cancel-btns">
                  <Button
                    className="submit-btn"
                    htmlType="submit"
                    type="primary"
                    // icon={<LoginOutlined />}
                    loading={isLoading}
                  >
                    {t("contactForm.submitBtn.label")}
                  </Button>
                </div>
              </div>

              {isLoading && <LoadingModal />}
            </Form>
          </div>
          <div className="map-wrap">
            <img src={mapImg} alt="map" />
          </div>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </section>
  );
};

export default ContactSection;
