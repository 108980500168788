import React from "react";
import { useController } from "react-hook-form";
import { Form, Input } from "antd";
import { useState } from "react";

const AntdTextarea = ({
  name,
  maxLength,
  rows,
  label,
  placeholder,
  prefix,
  showCount,
  validateStatus,
  errorMsg,
  control,
  className,
}) => {
  const { TextArea } = Input;
  const {
    // field: { ...inputProps }
    field,
  } = useController({
    name,
    control,
  });
  const [focused, setFocused] = useState(false);

  return (
    <Form.Item
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      colon={false}
      className={focused || field.value ? "focused" : "blured"}
    >
      <TextArea
        {...field}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(e) => {
          setFocused(false);
          field.onBlur(e);
        }}
        rows={rows}
        showCount={showCount}
        maxLength={maxLength}
        placeholder={placeholder}
        prefix={prefix}
        className={className}
      />
    </Form.Item>
  );
};

export default AntdTextarea;
