import { t } from "i18next";
import appStoreImg from "../../assets/imgs/app-store.png";
import googlePlayImg from "../../assets/imgs/google-play.png";
import CountDownCircle from "../offers-section/CountDownCricle";
import "./HeroSection.scss";

const HeroSection = ({ fetchedSettings }) => {
  return (
    <section className="hero-section">
      <div
        className="custom-container"
        style={{
          display: "grid",
        }}
      >
        <div className="text-img-wrap">
          <div className="text-wrap">
            <h1
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="800"
              className="bold-font"
            >
              {t("theFlash")}
            </h1>
            <h2
              data-aos="fade-right"
              data-aos-delay="400"
              data-aos-duration="800"
            >
              {/* {t("localInternational")} */}
              {fetchedSettings?.about || ""}
            </h2>
            {/* <h2
              data-aos="fade-right"
              data-aos-delay="600"
              data-aos-duration="800"
            >
              {t("harry")}
            </h2> */}

            <div className="get-app">
              <div className="stores-wrap">
                <a
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="800"
                  href={fetchedSettings?.google || "/"}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                >
                  <img src={googlePlayImg} alt="google play" />
                </a>
                <a
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="800"
                  href={fetchedSettings?.apple || "/"}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                >
                  <img src={appStoreImg} alt="app store" />
                </a>
              </div>
            </div>
          </div>

          <div
            className="hero-timer"
            data-aos="fade-up"
            data-aos-delay="700"
            data-aos-duration="800"
          >
            <div className="sec-timer">
              <h3
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="800"
              >
                {t("offerTime")}
              </h3>
              <h4
                data-aos="fade-up"
                data-aos-delay="700"
                data-aos-duration="800"
              >
                {t("getOffer")}
              </h4>
              <div
                data-aos="fade-left"
                data-aos-delay="700"
                data-aos-duration="800"
              >
                <CountDownCircle />
              </div>
            </div>
          </div>
          {/* <div
            data-aos="fade-up"
            data-aos-delay="1300"
            data-aos-duration="1000"
            className="img-wrap"
          >
            <img src={img} alt="city" />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
