/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import AboutSection from "./about-section/AboutSection";
import ContactSection from "./contact-section/ContactSection";
import DownloadAppSection from "./download-app-section/DownloadAppSection";
import HeroSection from "./hero-section/HeroSection";
import OffersSection from "./offers-section/OffersSection";
import TermsSection from "./terms-section/TermsSection";

const HomePage = ({ isLoading, fetchedSettings, scrollIntoView }) => {
  if (isLoading) {
    return (
      <div className="home-page">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 332,
            }}
          >
            <LoadingOutlined style={{ fontSize: 20 }} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="home-page">
      <HeroSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
      <AboutSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
      <OffersSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
      <DownloadAppSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
      <TermsSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
      <ContactSection
        fetchedSettings={fetchedSettings}
        scrollIntoView={scrollIntoView}
      />
    </div>
  );
};
export default HomePage;
