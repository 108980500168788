/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useController } from "react-hook-form";
import { Form, Input } from "antd";
import { useState } from "react";

const AntdTextField = ({
  disabled,
  setValue,
  control,
  name,
  type,
  label,
  placeholder,
  prefix,
  validateStatus,
  defaultValue,
  errorMsg,
  className,
  onChange,
  style,
  onInput,
  // value
}) => {
  const {
    // field: { ...inputProps }
    field,
  } = useController({
    name,
    control,
  });

  React.useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);
  const [focused, setFocused] = useState(false);
  return (
    <Form.Item
      style={style}
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      colon={false}
      className={focused || field.value ? "focused" : "blured"}
    >
      <Input
        onInput={(e) => {
          onInput && typeof onInput === "function" && onInput(e);
        }}
        disabled={disabled ? disabled : false}
        {...field}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(e) => {
          setFocused(false);
          field.onBlur(e);
        }}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e.target.value);
        }}
        className={className}
        placeholder={placeholder}
        prefix={prefix}
        type={type}
        size="large"
        // defaultValue={defaultValue}
        // value={field.value ? field.value : defaultValue}
      />
    </Form.Item>
  );
};

export default AntdTextField;
