import { t } from "i18next";
import { useRef } from "react";
import ScrollIntoView from "../../utils/ScrollIntoView";
import appStoreImg from "../../assets/imgs/app-store.png";
import googlePlayImg from "../../assets/imgs/google-play.png";
import mockImg from "../../assets/imgs/mock.png";

import "./DownloadAppSection.scss";

const DownloadAppSection = ({ scrollIntoView, fetchedSettings }) => {
  const secRef = useRef();

  ScrollIntoView({
    sectionName: "download_section",
    scrollIntoView,
    secRef,
  });

  return (
    <section ref={secRef} className="download-app-section">
      <div className="custom-container">
        <div className="sec-content">
          <div
            className="text-wrap"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="800"
          >
            <div className="main-t bold-font">{t("homeReq.title")}</div>
            <div className="sub-t">{t("homeReq.subTitle")}</div>

            <div
              className="get-app"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="800"
            >
              <p className="get-t">{t("homeReq.getApp")}</p>
              <div className="stores-wrap">
                <a
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="800"
                  href={fetchedSettings?.google || "/"}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                >
                  <img src={googlePlayImg} alt="google play" />
                </a>
                <a
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="800"
                  href={fetchedSettings?.apple || "/"}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                >
                  <img src={appStoreImg} alt="app store" />
                </a>
              </div>
            </div>
          </div>

          <div
            className="mockup-wrap"
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-duration="800"
          >
            <img src={mockImg} alt="send request" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadAppSection;
