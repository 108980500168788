import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App";
import "antd/dist/reset.css";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "./scss/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
