import React from "react";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import logoWhite from "../../assets/imgs/logo/logo-white.png";
import logoColored from "../../assets/imgs/logo/logo-colored.png";
import "./Logo.scss";

const Logo = memo(({ className, colored }) => {
  const { i18n } = useTranslation();
  return (
    <div
      style={{
        position: "relative",
      }}
      className={`${className} logo-wrap`}
      to="/"
    >
      {/* <img src="/assets/imgs/logo/logo-white.png" alt="app logo" /> */}

      {!colored ? (
        i18n.dir() === "rtl" ? (
          <img src={logoWhite} alt="app logo" />
        ) : (
          <img src={logoWhite} alt="app logo" />
        )
      ) : i18n.dir() === "rtl" ? (
        <img src={logoColored} alt="app logo" />
      ) : (
        <img src={logoColored} alt="app logo" />
      )}
    </div>
  );
});

export default Logo;
