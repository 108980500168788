/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

const ScrollIntoView = ({ sectionName, scrollIntoView, secRef }) => {
  useEffect(() => {
    if (scrollIntoView === sectionName) {
      window.scrollTo(0, secRef.current.offsetTop - 76);
    }
  }, [scrollIntoView]);
};

export default ScrollIntoView;
