/* eslint-disable eqeqeq */

const mainAppBarLinks = (i18n, t) => {
  const sharedLinks = () => {
    return [
      {
        id: "about_section",
        name: t("about"),
      },
      {
        id: "offers_section",
        name: t("offers"),
      },
      {
        id: "download_section",
        name: t("downloadApp"),
      },
      {
        id: "terms_section",
        name: t("terms"),
      },
      {
        id: "contact_section",
        name: t("contact"),
      },
    ];
  };

  return sharedLinks();
};

export default mainAppBarLinks;
