import { useRef } from "react";
import ScrollIntoView from "../../utils/ScrollIntoView";
import specialOfferImg from "../../assets/imgs/special-offer.jpg";
import "./AboutSection.scss";

const AboutSection = ({ scrollIntoView, fetchedSettings }) => {
  const secRef = useRef();

  ScrollIntoView({
    sectionName: "about_section",
    scrollIntoView,
    secRef,
  });

  return (
    <section ref={secRef} className="about-us-section">
      <div className="custom-container">
        <div className="sec-content-wrapper">
          <img alt="about" className="d-img" src={specialOfferImg} />
          <div className="sec-text">
            <div
              className="sec-title"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="800"
            >
              {/* One of those references was made by the big bad */}
              {fetchedSettings?.about || ""}
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="800"
              className="sec-desc"
            >
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s */}

              {fetchedSettings?.about_us_desc || ""}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
