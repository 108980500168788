import axios from "axios";

const getGeneralSettingsApi = async () => {
  try {
    const url = "/General/setting";
    const res = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getGeneralSettingsApi;
