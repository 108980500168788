import axios from "axios";

const contactApi = async (values) => {
  try {
    const url = "/General/contact_us";
    const res = await axios.post(url, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default contactApi;
